// http://ionicframework.com/docs/theming/
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./assets/css/ubuntu-google.css";
@import "./assets/css/colors.scss";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "../node_modules/ngx-lightbox/lightbox.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

body {
  font-family: Ubuntu;
}

// .ion-page {
//   top: constant(safe-area-inset-top); //for iOS 11.2
//   top: env(safe-area-inset-top); //for iOS 11.1
//   bottom: constant(safe-area-inset-bottom); //for iOS 11.2
//   bottom: env(safe-area-inset-bottom); //for iOS 11.1

// }

ion-app {
  margin-top: env(safe-area-inset-top);
  margin-bottom: env(safe-area-inset-bottom);
}

// .ios ion-footer .toolbar:last-child {
//   padding-bottom: calc(constant(safe-area-inset-bottom) + 10px);
//   padding-bottom: calc(env(safe-area-inset-bottom) + 10px);
//   // min-height: calc(44px + constant(safe-area-inset-bottom));
//   // min-height: calc(44px + env(safe-area-inset-bottom));
// }

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border: none;
  outline: 0 none;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.ng-select {
  &.ng-select-disabled {
    .ng-select-container {
      background-color: transparent !important;
    }
    .ng-arrow-wrapper {
      display: none;
    }
  }
}

button:focus {
  outline: none;
}
ion-content.dash-col {
  font-family: Ubuntu;
  --ion-background-color: #f6f6f6;
  left: 150px;
  position: relative;
  width: calc(100% - 150px);

  @media (max-width: 992px) {
    width: calc(100%);
    left: 0px;
    --ion-background-color: white;
  }

  &.padding-20 {
    --padding-top: 20px;
    --padding-bottom: 20px;
    --padding-start: 20px;
    --padding-end: 20px;
  }

  &.l-0 {
    left: 0px;
    width: 100%;
    --ion-background-color: white;
  }
}

ion-content.white {
  --ion-background-color: white;
  position: relative;
}

.alert-wrapper {
  max-width: unset !important;
  width: 35% !important;
}

.stat-container {
  width: calc(100% - 150px);
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.no-padding {
  padding: 0px;
}

.upper-case {
  text-transform: uppercase;
}

.flex {
  display: flex;
}

.tab {
  display: none !important;
}

ion-toast {
  --width: 120px;
  text-align: center;
  --background: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 1200px) {
}

@media (max-width: 992px) {
  .web {
    display: none !important;
  }

  .tab {
    display: none !important;
  }

  .header-tool-bar {
    --background: white;
    position: absolute;
    --border-style: none;
    z-index: 3;
    // width: 65px;
    --min-height: 60px;
    border-bottom: 1px solid #d4d4d4;

    .i-back {
      color: #6c757d !important;
      font-size: 27px;
      margin-left: 5px;
      position: absolute;
      top: 14px;
    }
    &.profile {
      border-bottom: none;
      height: 50px;
    }
    ion-back-button {
      --color: #6c757d !important;
    }
    .save-btn-header {
      float: right;
      background: transparent;
      font-size: 15px;
      color: #6c757d;
      font-weight: 600;
    }
  }
}

@media (min-width: 992px) {
  .mobile {
    display: none !important;
  }
}

@media (min-width: 1600px) {
}

@media (max-width: 1180px) and (min-width: 990px) {
}

@media (max-width: 852px) and (min-width: 768px) {
}

@media (max-width: 1380px) and (min-width: 1300px) {
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #d4d4d4;
}
.modal-css {
  --border-radius: 5px;
  --max-width: 550px;
  --max-height: 350px;
}

.modal-css-note {
  --border-radius: 5px;
  --max-width: 550px;
  --max-height: 400px;
}

.modal-css-vehicle {
  --border-radius: 5px;
  --max-width: 550px;
  --max-height: 390px;
}

.modal-css-select-user {
  --border-radius: 5px;
  --max-width: 550px;
  --max-height: 390px;
}

.modal-css-advance-filter {
  --border-radius: 5px;
  --max-width: 550px;
  --height: 500px;
}

.modal-note-css {
  --border-radius: 10px;
  --max-width: 520px;
  --max-height: 330px;
}

.modal-job-note-css {
  --border-radius: 10px;
  --max-width: 550px;
  // --max-height: 330px;
}

.modal-css-advance-project-filter {
  --border-radius: 5px;
  --max-width: 550px;
  --height: 685px;
}

.modal-css-advance-job-ecd-filter {
  --border-radius: 5px;
  --max-width: 550px;
  --height: 440px;
}

.modal-css-advance-job-filter {
  --border-radius: 5px;
  --max-width: 550px;
  --height: 730px;
}

.modal-css-task {
  --border-radius: 5px;
  --max-width: 850px;
  --height: 660px;
  // --height: calc(100% - 10vh);
  --width: 850px;

  @media (max-width: 992px) {
    --height: 100%;
  }
}

.modal-css-lg {
  --border-radius: 5px;
  --max-width: 850px;
  --max-height: 600px;
  --width: 850px;
}
.modal-css-cus-select {
  --border-radius: 5px;
  --max-width: 550px;
  --max-height: 390px;
}

.modal-css-rental-form {
  --border-radius: 5px;
  --max-width: 550px;
  --max-height: 280px;
}

.modal-css-automation {
  --border-radius: 5px;
  --max-width: 850px;
  --height: 710px;
  --width: 850px;

  @media (max-width: 992px) {
    --height: 100%;
  }
}

.modal-css-tags {
  --border-radius: 5px;
  --max-width: 380px;
  --max-height: 600px;
  --height: 600px;

  @media (max-width: 992px) {
    --height: 100%;
  }
}

.modal-add-new-due-date {
  --border-radius: 5px;
  --max-width: 680px;
  --max-height: 445px;
}

@media only screen and (min-height: 600px) and (min-width: 768px) {
  .modal-css-lg {
    --height: 560px;
  }
}
.filter-toggle {
  background-color: white !important;
  border: none !important;
  color: white !important;
  padding: 0px !important;
  padding-left: 5px !important;
  float: right;
  .fa {
    color: gray;
  }
  &:focus {
    border: none !important;

    box-shadow: none !important;
    .fa {
      color: #c40200;
    }
  }
}
.dropdown-menu {
  &.filter {
    max-height: 65vh;
    overflow-y: scroll;
  }
  .dropdown-item {
    &.selected {
      color: #16181b;
      text-decoration: none;
      background-color: #fff4f4;
    }
    &:hover {
      background-color: #fff4f4;

      color: #16181b;
    }
  }
}

.popover-content {
  left: 12px !important;
}

.job-list-filter {
  .multiselect-dropdown {
    border: 0px;
    .dropdown-btn {
      border: 0px !important;
      width: 22px !important;
      font-size: 12px;
      padding: 0px 5px !important;
      .dropdown-up {
        border-bottom: 5px solid transparent !important;
        border-left: 5px solid transparent !important;
        border-right: 5px solid transparent !important;
      }
      .dropdown-down {
        border-top: 1px solid transparent !important;
        border-left: 1px solid transparent !important;
        border-right: 1px solid transparent !important;
      }
      .dropdown-multiselect__caret:before {
        color: transparent !important;
        border-color: transparent !important;
      }
    }
    .dropdown-list {
      width: max-content !important;
    }
  }
  .dropdown-toggle {
    &::after {
      display: block !important;
    }
  }
}

ionic-selectable {
  .ionic-selectable-value {
    display: flex;

    span {
      width: 100%;
      display: flex;
      text-overflow: ellipsis;
      overflow: hidden;
      .ionic-selectable-value-item {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: unset;
        padding-right: 8px;

        &:not(:first-child) {
          margin-left: -0.3em;
        }

        &:first-child:empty ~ .comma:not(:empty) {
          margin-left: 0;
        }

        &:first-child:empty ~ .comma:not(:empty) ~ .comma:not(:empty) {
          margin-left: -0.3em;
        }

        &:empty {
          display: none;
        }

        &:not(:first-child):before {
          content: ", ";
        }

        &:empty + .comma:not(:empty):before {
          content: "";
        }

        &:not(:empty) ~ .comma:empty + .comma:not(:empty):before {
          content: ", ";
        }
      }
    }
  }
}
.ng-dropdown-panel {
  opacity: unset !important;
}

.sidemenu-popover-class {
  --width: 218px;
  --background: $light-gray;
  --backdrop-opacity: 0;
  left: 138px;
  top: -60px;
}

.remove-icon-search-bar {
  position: relative;
  right: 22px;
  top: 2px;
  cursor: pointer;
  color: $dashboard-table-row-red;
}

.backdateToggle {
  padding: 6px 2px !important;

  &.toggle-checked {
    .toggle-icon {
      background-color: $dark-red !important;
    }
  }

  .toggle-icon {
    width: 31px !important;
    height: 18px !important;
    background: $table-bar-gray !important;
    border: 0.8px solid $border-color !important;

    .toggle-icon-wrapper {
      .toggle-inner {
        width: 16px !important;
        height: 16px !important;
      }
    }
  }
}
