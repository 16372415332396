//main colors
$bg-color: #f6f6f6;
$border-color: #d4d4d4;
$middle-gray: #6e6e6e;
$border-color-dark: #b5b5b5;
$border-color-light: #e9ecef;
$disable-gray: #d4d4d4;
$medium-gray: #606060;
$light-gray: #2b2d32;
$dark-gray: #222326;
$stage-bg-gray: #f4f4f4;
$blue: #00b6ea;
$light-blue: #e5effc;
$dark-gray: #656565;
$very-light-gray: #c4c4c4;
$stat-gray: #8f8f8f;
$purple: #7a6fbe;
$green: #04bf00;
$aqua: #12afcb;
$aquagreen: #22baa0;
$light-green: #b7dfd8;
$orange: #ea6a00;
$transparent-red: #fff4f4;
$light-red: #ffb3b2;
$red: #ff0300;
$dark-red: #c40200;
$transparent-yellow: #fff4e1;
$yellow: #eab600;
$page-white: #f6f6f6;
$side-bar-background: #222326;
$stage-black: #3b3d42;
$black: #111111;
$bottom-title: #dddcdc;
$status-brown: #c8572e;
$dashboard-col: #eaeaea;
$dashboard-col-title: #828282;
$task-title: #41434b;
$flag-green: #42b09e;
$flag-blue: #3c66b8;
$flag-red: #ff5a60;
$d-card-txt: #6b6b6b;
$separator-mobile: #eeeeee;
$task-name-black: #383a42;
$new-title: #606060;
$white: #ffffff;
$status-paused: #aaacaf;
$delete-icon-color: #9e9e9e;
$title-black-d2: #212121;
$dropdown-border-bottom: #f2f2f2;
$instruction-attribute: #ffbd82;
$dashboard-box-color: #faeaea;
$manager-color: #e28685;
$dashboard-box-yellow: #eab601;
$dashboard-table-row: #242424;
$dashboard-table-row-red: #cb0200;
$dashboard-table-red: #ff0000;
$border-color: #e5e5e5;
$job-col-dash: #e9e9e9;
$light-delay: #c1b7ff;
$medium-delay: #ffb145;
$workflow-yellow: #f9c13f;
$tags-pink: #c377e0;
$colors-orange: #fb9f1b;
$overhead-back: #f9fbfc;
$overhead-gray: #003e49;
$placeholder-color: #a3a3a3;
$dashboard-dark-red: #e35b5b;

//chart colors
//pie chart colors
$search-font-gray: #a2a2a2;
$table-bar-gray: #e9ecef;
$table-line-gray: #e9ecef;
$table-font-gray: #606060;
$table-ongoing-blue: #7a6fbe;
$table-complete-green: #22baa0;
$stage-icon-gray: #bebebe;
$stage-name-gray: #6c6c6c;
$pie-chart-orange: #f9a44a;
$pie-chart-red: #f25f67;
$pie-chart-gray: #49637c;
$pie-chart-blue: #47a5d9;
$delay-red: #ff3a38;

//bar chart colors
$bar-chart-dark-blue: #2273e3;
$bar-chart-light-blue: #7aa7e5;

//panel callout

$panel-callout-background: #e8e8e8;
$panel-callout-border: #d8d8d8;
$panel-callout-color: #c0c0c0;
$tag-hwc-purple: #cfbbe6;
$tag-txt-purple: #8348c8;
$icon-gray-j: #979797;
